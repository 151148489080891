<template>
  <div>
    <GrModal
      name="Modal-filter-traffic"
      id="Modal-filter-traffic"
      idModal="Modal-filter-traffic"
      ref="Modal-filter-traffic"
      size="lg"
      title="Filtros"
    >
      <div class="clearFilters">
        <p>Filtre pelo seu tráfego</p>
      </div>
      <div>
        <b-form>
          <b-row style="margin: 70px 0px">
            <b-col>
              <b-form-group
                label="Plano"
                label-for="method"
                class="form-relative"
              >
                <!-- <multiselect
                  id="channel"
                  v-model="selected_plan"
                  label="title"
                  track-by="id"
                  placeholder="Selecione o plano"
                  selectLabel=""
                  deselectLabel=""
                  selectedLabel=""
                  open-direction="bottom"
                  :options="all_plans"
                  :multiple="false"
                  :taggable="false"
                  :internal-search="false"
                  :clear-on-select="false"
                  :close-on-select="true"
                  :show-no-results="true"
                  :hide-selected="false"
                  ><span slot="noResult"
                    >Nenhum plano encontrado.</span
                  ></multiselect
                > -->
                <!-- <BaseSelect
                  id="channel"
                  :selectModel="selected_plan"
                  placeholder="Selecione um plano"
                  trackname="title"
                  track-by="id"
                  :array="all_plans"
                  :multiple="false"
                  selectLabel=""
                  :searchable="true"
                  deselectLabel=""
                  selectedLabel=""
                  @change="selected_plan = $event"
                  @callback="fetchPlans($event)"
                  ><span slot="noResult">Nenhum plano encontrado.</span>
                </BaseSelect> -->
                <BaseSelect
                  :selectModel="selected_plan"
                  placeholder="Selecionar o plano"
                  track-by="id"
                  :array="all_plans"
                  trackname="title"
                  :multiple="false"
                  :searchable="true"
                  selectLabel="Clique para selecionar"
                  deselectLabel="Clique para remover"
                  @callback="fetchPlans($event)"
                  @search="debouncePlans($event)"
                  :loading="loadingPlans"
                  @change="selected_plan = $event"
                >
                </BaseSelect>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Canal"
                label-for="method"
                class="form-relative"
              >
                <multiselect
                  id="channel"
                  v-model="selected_channel"
                  label="title"
                  track-by="id"
                  placeholder="Selecione o canal"
                  selectLabel=""
                  deselectLabel=""
                  selectedLabel=""
                  open-direction="bottom"
                  :options="channels"
                  :multiple="false"
                  :taggable="false"
                  :internal-search="false"
                  :clear-on-select="false"
                  :searchable="false"
                  :close-on-select="true"
                  :show-no-results="true"
                  :hide-selected="false"
                  ><span slot="noResult"
                    >Nenhum plano encontrado.</span
                  ></multiselect
                >
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </div>
      <template v-slot:footer="{ cancel }">
        <div>
          <BaseButton
            variant="link-info"
            class="mr-4"
            @click="cancel"
          >
            Cancelar
          </BaseButton>
          <BaseButton variant="primary" class="btn-filter" @click="onSubmit"
            >Aplicar filtro</BaseButton
          >
        </div>
      </template>
    </GrModal>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
//
import PlanService from "@/services/resources/PlanService";
const servicePlan = PlanService.build();
//
export default {
  name: "ModalFilterTraffic",
  components: {
    Multiselect,
  },

  data() {
    return {
      stop: false,
      loading: false,
      loadingPlans: false,
      pagePlans: 1,
      all_plans: [],
      selected_plan: { id: null, title: "Selecione o plano" },
      channels: [
        { id: 1, title: "MetaAds" },
        { id: 2, title: "GoogleAds" },
        { id: 3, title: "Twitter" },
        { id: 4, title: "Linkedin" },
        { id: 5, title: "TikTok" },
      ],
      selected_channel: { id: null, title: "Selecione o canal" },

      channelsNull: [],
      plansNull: [],
    };
  },

  methods: {
    debouncePlans: _.debounce(function (query) {
      if (query === "") {
        this.loadingPlans = false;
      } else {
        this.searchPlans = query;
        this.fetchPlans(this.pagePlans, query);
      }
    }, 500),
    fetchPlans(page = 1, query = "") {
      if (this.stopPlans || this.loadingPlans) {
        return;
      }

      this.loadingPlans = true;

      var data = {
        // id: `list?page=${page}&order_by=title&order=ASC&search=${query}`,
        page: page,
        order_by: "title",
        order: "ASC",
        search: query,
      };

      servicePlan
        .search(data)
        .then((resp) => {
          if (resp.data.length < resp.per_page && query == "") {
            this.stopPlans = true;
          }

          this.all_plans = this.all_plans.concat(resp.data);
          this.all_plans = this.all_plans.filter(
            (item, index, opt) =>
              index === opt.findIndex((t) => t.id === item.id)
          );
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loadingPlans = false;
        });
    },

    onSubmit() {
      this.$emit("selected-channel-title", this.selected_channel);
      this.$emit("selected-plan-id", this.selected_plan);
      this.$emit("create-traffic");
      this.$grModal.hide("Modal-filter-traffic", this);
    },
  },
};
</script>

<style scoped lang="scss">
.clearFilters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;

  p {
    margin: 0;
    font-size: 14px;
    font-weight: normal;
    color: #81858e;
    line-height: 1.5;
  }
}
</style>
