import Rest from '@/services/Rest';

/**
 * @typedef {DashboardTraffic}
 */
export default class DashboardTraffic extends Rest {
    /**
     * @type {String}
     */
    static resource = 'dashboard/graph/traffic'


}
